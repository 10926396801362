<template>
  <v-card>
    <Header :title="$t('profile.your-feed')" >
      <v-btn text dark :to="{name:'profileUpload'}"><v-icon small class="fa fa-camera-alt" /><span class="d-none ml-1 d-sm-flex">{{$t('profile.upload-photo.photo')}}</span></v-btn>    
    </Header>

    <div v-if="feedItems && feedItems.length > 0">
      <QuickSurvey v-if="false && feedItems.some(x => x.type == 'RESULT_PHOTO' || x.type == 'EVENT_PHOTO')" class="mx-4 mt-4" name="202103-selfie" question="What do you think of the selfie upload?" :items="[{value: 'very', text: '😀 Brilliant'},  {value: 'like', text: '😊 I like it'}, {value: 'neutral', text: '😐 Not sure'}, {value: 'not-working', text: '🙁 Didn\'t work for me'}]" />
      <FeedList :feedItems="feedItems" allowDelete @deleteItem="deleteFeedItem" />
    </div>
  </v-card>
</template>



<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import profileService from "@/services/profileService";
import { EventBus } from '@/plugins/eventbus.js';
import ShareButton from '@/components/ShareButton.vue';
import FeedList from '@/components/FeedList.vue';
import Header from './_Header.vue';
import QuickSurvey from "@/components/QuickSurvey";
import tenants from '@/data/tenants.config'
const tenant = tenants.current();

export default {
  name: "Profile",
  components: {
    Header,
    ShareButton,
    QuickSurvey,
    FeedList,
  },
  props: {
  },
  data() {
    return {
      profile: null,
      providerUrls: null,
      feedItems: null,
    };
  },
  async mounted() {
    await this.getProfile();
    
    EventBus.$on('login-state-change', async user => {
      await this.getProfile();
    });
  },
  methods: {
    async getProfile() {
      if (this.user) {
        try{
          var response = await profileService.get();
        }
        catch {
          // network error occur after api has been deployed causing tokens to be invalidated
          // reload the page to prevent empty profile page from showing
          //window.location.reload(true);
          return;
        }
        this.profile = response.data;
        this.feedItems = (await profileService.getFeed()).data.data;
      }
    },
    async deleteFeedItem(id) {
      if (confirm('Are you sure you want to delete this item?')){
        const response = (await profileService.deleteFeedItem(id)).data;
        this.$helpers.toastResponse(this, response, 'The item has been deleted successfully.');
        await this.getProfile();
      }
    },
  },
  computed: {
    
    ...mapGetters({
      user: "user"
    })
  },

};
</script>


